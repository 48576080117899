var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-flex",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "6" } }, [
                _c("h3", [_vm._v("Período de Matrícula")])
              ]),
              _c(
                "v-col",
                { staticClass: "text-start", attrs: { cols: "6" } },
                [_c("EnrollmentPeriodInsert")],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-flex",
        [
          _c(
            "keep-alive",
            [
              _c(
                _vm.component,
                _vm._b(
                  {
                    tag: "component",
                    on: {
                      "close-enrollment": function($event) {
                        return _vm.showRequests()
                      }
                    }
                  },
                  "component",
                  _vm.componentProps,
                  false
                )
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }