<template>
  <v-container>
    <v-flex>
      <v-row>
        <v-col cols="6">
      <h3>Período de Matrícula</h3>
        </v-col>
        <v-col cols="6" class="text-start">         
          <EnrollmentPeriodInsert></EnrollmentPeriodInsert>
        </v-col>
      </v-row>
    </v-flex>
    <v-flex>
      <keep-alive>
        <component
          :is="component"
          v-bind="componentProps"
          v-on:close-enrollment="showRequests()"
        ></component>
      </keep-alive>
    </v-flex>
  </v-container>
</template>

<script>
import EnrollmentPeriodInsert from "./EnrollmentPeriodInsert";
import EnrollmentPeriodTable from "./EnrollmentPeriodTable";

export default {
  name: "EnrollmentPeriod",
  props: [],
  components: { EnrollmentPeriodInsert, EnrollmentPeriodTable },

  data() {
    return {
      component: null,
      componentProps: null,
    };
  },

  created() {
    this.component = EnrollmentPeriodTable;
  },

  methods: {
    showRequests() {},
  },
};
</script>

<style>
</style>