<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="dialog = true"
          v-bind="attrs"
          v-on="on"
          :disabled="disabled"
          x-small
          outlined
          color="red"
          ><v-icon small color="red">delete</v-icon></v-btn
        >
      </template>
      <span>Remover o período de matrícula?</span>
    </v-tooltip>

    <v-dialog v-model="dialog" max-width="500" @keydown.esc="dialog = false">
      <v-card class="py-5">
        <v-card-title>
          <div class="title">Deseja mesmo remover o período de matrícula?</div>
        </v-card-title>
        <v-card-text>
          <div>
            A operação <strong>apagará</strong> todos as solicitações de matrículas relacionadas
            com o período <strong>[ {{ item.begin }} - {{ item.end }} ]!</strong>
          </div>
        </v-card-text>
      <v-card-actions>
        <v-row justify="center">
          <v-col cols="6" class="text-center"><v-btn outlined color="red" @click="dialog = false">Cancelar<v-icon class="ml-2">cancel</v-icon></v-btn></v-col>
          <v-col cols="6" class="text-center"><v-btn outlined color="green" @click="delAsyncEnrollmentPeriod()">Remover<v-icon class="ml-2">delete</v-icon></v-btn></v-col>
          <v-col cols="12"> <v-progress-linear indeterminate v-show="progress" color="red"></v-progress-linear> </v-col>
        </v-row>
      </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "EnrollmentPeriodDelete",
  props: ["item", "disabled"],
  components: {},
  data() {
    return {
      dialog: false,
      progress: false,
    };
  },

  created() {
    //console.log(this.item);
  },

  methods: {
    ...mapActions("enrollmentPeriod", ["ActionDeleteEnrollmentPeriod"]),

    async delAsyncEnrollmentPeriod() {
      try {
        this.progress = true;
        await this.ActionDeleteEnrollmentPeriod(this.item.id);
        this.dialog = false;
      } catch (e) {
        console.error(e);
      } finally {
        this.progress = false;
      }
    },
  },
};
</script>

<style>
</style>