var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { top: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            "x-small": "",
                            outlined: "",
                            color: "red"
                          },
                          on: {
                            click: function($event) {
                              _vm.dialog = true
                            }
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { small: "", color: "red" } }, [
                        _vm._v("delete")
                      ])
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v("Remover o período de matrícula?")])]
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.dialog = false
            }
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "py-5" },
            [
              _c("v-card-title", [
                _c("div", { staticClass: "title" }, [
                  _vm._v("Deseja mesmo remover o período de matrícula?")
                ])
              ]),
              _c("v-card-text", [
                _c("div", [
                  _vm._v(" A operação "),
                  _c("strong", [_vm._v("apagará")]),
                  _vm._v(
                    " todos as solicitações de matrículas relacionadas com o período "
                  ),
                  _c("strong", [
                    _vm._v(
                      "[ " +
                        _vm._s(_vm.item.begin) +
                        " - " +
                        _vm._s(_vm.item.end) +
                        " ]!"
                    )
                  ])
                ])
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-center", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { outlined: "", color: "red" },
                              on: {
                                click: function($event) {
                                  _vm.dialog = false
                                }
                              }
                            },
                            [
                              _vm._v("Cancelar"),
                              _c("v-icon", { staticClass: "ml-2" }, [
                                _vm._v("cancel")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "text-center", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { outlined: "", color: "green" },
                              on: {
                                click: function($event) {
                                  return _vm.delAsyncEnrollmentPeriod()
                                }
                              }
                            },
                            [
                              _vm._v("Remover"),
                              _c("v-icon", { staticClass: "ml-2" }, [
                                _vm._v("delete")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-progress-linear", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.progress,
                                expression: "progress"
                              }
                            ],
                            attrs: { indeterminate: "", color: "red" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }