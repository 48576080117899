<template>
  <div>
    <v-flex xs12 md3>
      <v-row wrap justify="start" align="start">
        <v-col cols="12">
          <v-text-field
            label="Filtrar período"
            single-line
            v-model="search"
            append-icon="search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-flex>
    <v-flex>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="items"
        item-key="id"
        :loading="loading"
        hide-default-footer
        loading-text="Buscando períodos de matrícula"
        no-data-text="Não foram encontradas períodos de matrícula"
      >
        <template v-slot:item="props">
          <tr>
            <td class="text-xs-left">{{ dmy(props.item.begin) }}</td>
            <td class="text-xs-left">{{ dmy(props.item.end) }}</td>
            <td class="text-xs-left">
              <v-tooltip top v-for="c in props.item.classes" v-bind:key="c.id">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    small
                    outlined
                    class="mr-1 mb-2"
                    color="black"
                    >{{ c.subject.code }}</v-chip
                  >
                </template>
                <span>{{ c.subject.code + " - " + c.subject.name }}</span>
              </v-tooltip>
            </td>
            <td class="text-xs-left">{{ props.item.username }}</td>
            <td class="text-xs-left">
              <v-chip small outlined :color="props.item.removed.color">{{
                props.item.removed.status
              }}</v-chip>
            </td>
            <td class="text-xs-left">
              <EnrollmentPeriodUpdate :item="props.item"  :disabled="props.item.removed.status === 'Encerrado'"></EnrollmentPeriodUpdate>
            </td>
            <td class="text-xs-left">
              <EnrollmentPeriodDelete :item="props.item"  :disabled="props.item.removed.status === 'Encerrado'"></EnrollmentPeriodDelete>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { formatDate } from "@/utils/formatDate";
import EnrollmentPeriodDelete from './EnrollmentPeriodDelete'
import EnrollmentPeriodUpdate from './EnrollmentPeriodUpdate'

export default {
  name: "EnrollmentPeriod",
  props: [],
  components: { EnrollmentPeriodDelete, EnrollmentPeriodUpdate },
  data() {
    return {
      search: "",
      items: [],
      disabled: false,
      loading: false,
      headers: [
        {
          sortable: true,
          text: "Início em",
          value: "begin",
          align: "left",
        },
        {
          sortable: true,
          text: "Término",
          value: "end",
          align: "left",
        },
        {
          sortable: false,
          text: "Turmas",
          value: "classes",
          align: "left",
        },
        {
          sortable: true,
          text: "Criado por",
          value: "username",
          align: "left",
        },
        {
          sortable: false,
          text: "Situação",
          value: "removed",
          align: "left",
        },
        {
          sortable: false,
          text: "Alterar",
          value: "alter",
          align: "left",
        },
        {
          sortable: false,
          text: "Excluir",
          value: "delete",
          align: "left",
        },
      ],
    };
  },

  created() {
    this.getAsyncEnrollmentPeriod();
  },

  computed: {
    ...mapState("enrollmentPeriod", ["enrollmentPeriod"]),
  },

  methods: {
    ...mapActions("enrollmentPeriod", ["ActionGetEnrollmentPeriod"]),

    async getAsyncEnrollmentPeriod() {
      try {
        this.disabled = true;
        await this.ActionGetEnrollmentPeriod();
      } catch (err) {
        console.error(err);
      } finally {
        this.disabled = false;
      }
    },

    dmy(dt) {
      return formatDate.dmy(
        new Date(
          new Date(dt).getTime() + new Date(dt).getTimezoneOffset() * 60 * 1000
        )
      );
    },
  },

  watch: {
    enrollmentPeriod(newValue) {
      if (newValue) {
        this.items = [];
        newValue.map((e) => {
          this.items.push({
            id: e.id,
            begin: e.beginningEnrollmentPeriod,
            end: e.endEnrollmentPeriod,
            username: e.username,
            classes: e.classes,
            removed:
              e.removed === null || e.removed === false
                ? { status: "Aberto", color: "green" }
                : { status: "Encerrado", color: "red" },
          });
        });
      }
    },
  },
};
</script>

<style>
</style>