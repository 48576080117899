<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          outlined
          color="green"
          x-small
          :disabled="disabled"
          @click="dialog = true"
          ><v-icon small>add</v-icon></v-btn
        >
      </template>
      <span>Inserir novo período acadêmico</span>
    </v-tooltip>
    <v-dialog v-model="dialog" max-width="1000" @keydown.esc="dialog = false">
      <v-card outlined>
        <v-card-title>
          <div>Período de Matrículas</div>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12">
              <v-card outlined class="pt-3 px-3">
                <v-row justify="center">
                  <v-col cols="4">
                    <v-autocomplete
                      :items="semesters"
                      v-model="semester"
                      outlined
                      dense
                      label="semestre"
                      placeholder="semestre"
                      no-data-text="Sem número de semestre"
                      persistent-hint
                      hint="semestre"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      :items="years"
                      v-model="year"
                      outlined
                      dense
                      label="ano"
                      placeholder="ano"
                      no-data-text="Sem número de ano"
                      persistent-hint
                      hint="ano"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      :disabled="disabled || semester == null || year == null"
                      outlined
                      color="green"
                      @click="getAsyncClassesByLevelAndYearAndSemester()"
                      >Buscar<v-icon class="ml-2">search</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="3">
                  <v-menu
                    :disabled="items.length == 0"
                    ref="beginMenu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="beginDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="items.length == 0"
                        persistent-hint
                        hint="data de início"
                        outlined
                        dense
                        v-model="beginDate"
                        label="Data de Início"
                        append-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="beginDate"
                      no-title
                      scrollable
                      locale="br"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.beginMenu.save(beginDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="3">
                  <v-menu
                    :disabled="items.length == 0"
                    ref="endMenu"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="endDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="items.length == 0"
                        persistent-hint
                        hint="data de início"
                        outlined
                        dense
                        v-model="endDate"
                        label="Data de Término"
                        append-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      no-title
                      scrollable
                      locale="br"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu2 = false">
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.endMenu.save(endDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-select
                :disabled="items.length == 0"
                v-model="selectedItems"
                :items="items"
                :item-text="'subjectCode'"
                chips
                draggable
                deletable-chips
                dense
                clearable
                outlined
                single-line
                multiple
                return-object
                persistent-hint
                label="Turmas"
                no-data-text="Nenhum turma encontrada para inserir em período acadêmico."
                hint="Selecione as turmas que deseja incluir no período de matrícula."
                color="blue-grey lighten-4"
              >
                <template v-slot:selection="data">
                  <v-chip
                    class="draggable-css"
                    draggable
                    close
                    small
                    v-bind="data.attrs"
                    :key="data.id"
                    :input-value="data.selected"
                    @mousedown.stop
                    @click.stop
                    @click:close="removeSelected(data.item)"
                    :color="
                      'complete' === 'complete'
                        ? 'blue lighten-4'
                        : 'complete' === 'ongoing'
                        ? 'blue'
                        : 'green'
                    "
                  >
                    {{ data.item.subjectCode }}-{{ data.item.code }}
                  </v-chip>
                </template>

                <template v-slot:item="props">
                  <v-list-item-content class="pa-1">
                    {{ props.item.subjectCode }}-{{ props.item.code }} -
                    {{ props.item.subjectName }}
                    <v-list-item-subtitle
                      class="d-flex justify-start"
                      v-html="getClassProfessor(props.item)"
                    ></v-list-item-subtitle>
                    <v-list-item-action
                      class="d-flex justify-end"
                      v-html="getClassSchedule(props.item)"
                    ></v-list-item-action>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mb-5">
          <v-row justify="center">
            <v-col cols="6" class="text-center">
              <v-btn outlined color="red" :disabled="disabled" @click="cancel()"
                >Cancelar<v-icon class="ml-2">cancel</v-icon></v-btn
              >
            </v-col>
            <v-col cols="6" class="text-center">
              <v-btn
                @click="postAsyncEnrollmentPeriod()"
                outlined
                color="green"
                :disabled="disabled || selectedItems.length == 0"
                >Adicionar<v-icon class="ml-2">check</v-icon></v-btn
              >
            </v-col>
            <v-col cols="12" class="text-center">
              <label class="">{{ errorMessage }}</label>
            </v-col>
            <v-col cols="12" class="mb-2 mt-1">
              <v-progress-linear
                indeterminate
                color="green"
                v-show="progress"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "EnrollmentPeriodInsert",
  props: [],
  components: {},
  data() {
    return {
      errorMessage: "",
      progress: false,
      dialog: false,
      disabled: false,
      items: [],
      selectedItems: [],
      beginDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu2: false,
      modal: false,
      endMenu: null,
      beginMenu: null,
      year: null,
      semester: null,
      semesters: [0, 1, 2, 3, 4, 5, 6],
      years: [new Date().getFullYear(), new Date().getFullYear() + 1],
    };
  },

  created() {},

  computed: {
    ...mapState("classes", ["classes"]),
  },

  methods: {
    ...mapActions("enrollmentPeriod", ["ActionPostEnrollmentPeriod"]),
    ...mapActions("classes", ["ActionGetClassesByLevelAndYearAndSemester"]),

    async getAsyncClassesByLevelAndYearAndSemester() {
      this.selectedItems = [];
      if (this.year !== null && this.semester !== null) {
        try {
          this.disabled = true;
          this.progress = true;
          let path = {
            level: "p",
            year: this.year,
            semester: this.semester,
          };
          await this.ActionGetClassesByLevelAndYearAndSemester(path);
        } catch (e) {
          this.errorMessage = e.bodyText;
        } finally {
          this.disabled = false;
          this.progress = false;
        }
      }
    },

    async postAsyncEnrollmentPeriod() {
      try {
        this.disabled = true;
        this.progress = true;
        let payload = {
          begin: this.beginDate,
          end: this.endDate,
          classesId: this.catchClassesID(),
        };
        await this.ActionPostEnrollmentPeriod(payload);
        this.cancel();
      } catch (e) {
        this.errorMessage = e.bodyText;
      } finally {
        this.disabled = false;
        this.progress = false;
      }
    },

    getClassProfessor(item) {
      let value = "";
      if (item)
        for (let professor of item.professors) value += professor.name + " ";
      else value = "";
      return value;
    },

    getClassSchedule(item) {
      let value = "";
      if (!isNaN(item))
        for (let schedule of item.classSchedules) value += schedule + " ";
      else value = "";
      return value;
    },

    removeSelected(item) {
      const index = this.selectedItems.findIndex((v) => v.id === item.id);
      if (index >= 0) this.selectedItems.splice(index, 1);
    },

    cancel() {
      this.selectedItems = [];
      this.dialog = false;
      this.errorMessage = "";
      this.beginDate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.endDate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
    },

    catchClassesID() {
      let ids = [];
      this.selectedItems.map((e) => {
        ids.push(e.id);
      });
      return ids;
    },
  },

  watch: {
    classes(newValue) {
      if (newValue) {
        newValue.map((e) => {
          this.items.push({
            id: e.id,
            subjectCode: e.subject.code,
            subjectName: e.subject.name,
            code: e.code,
            professors: e.professors,
            classSchedules: e.classSchedules,
          });
        });
      }
    },
  },
};
</script>

<style>
</style>