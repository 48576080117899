<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          outlined
          color="amber"
          x-small
          :disabled="disabled"
          @click="dialog = true"
          ><v-icon small>edit</v-icon></v-btn
        >
      </template>
      <span>Alterar o período acadêmico</span>
    </v-tooltip>
    <v-dialog v-model="dialog" max-width="1200" @keydown.esc="dialog = false">
      <v-card outlined>
       <!--  <div class="text-center mt-5">
          <span class="red--text"><strong>Em desenvolvimento...</strong></span>
        </div> -->
        <v-card-title>
          <div>Alterar o Período de Matrículas</div>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12">
              <v-row>
                <v-col cols="4">
                  <v-menu
                    :disabled="classesByPeriod.length == 0"
                    ref="beginMenu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="beginDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="classesByPeriod.length == 0"
                        :rules="beginDateRules"
                        persistent-hint
                        hint="data de início"
                        outlined
                        dense
                        v-model="beginDate"
                        label="Data de Início"
                        append-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:append="menu = true"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="beginDate"
                      no-title
                      scrollable
                      locale="br"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.beginMenu.save(beginDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4">
                  <v-menu
                    :disabled="classesByPeriod.length == 0"
                    ref="endMenu"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="endDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="classesByPeriod.length == 0"
                        :rules="endDateRules"
                        persistent-hint
                        hint="data de término"
                        outlined
                        dense
                        v-model="endDate"
                        label="Data de Término"
                        append-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:append="menu2 = true"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      no-title
                      scrollable
                      locale="br"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu2 = false">
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.endMenu.save(endDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>

            <v-row wrap justify="center">
              <v-col cols="12" sm="12" md="5">
                <v-card
                  outlined
                  class="mt-2"
                  max-height="400px"
                  min-height="400px"
                >
                  <v-card-title>
                    <div>Turmas</div>
                  </v-card-title>
                  <v-card-text>
                    <draggable
                      v-model="classesByPeriod"
                      group="id"
                      @start="drag = true"
                      @end="drag = false"
                    >
                      <v-chip
                        class="my-1 mx-1"
                        color="blue-grey lighten-3"
                        @dblclick="selectItem(element)"
                        @mousedown.stop
                        @click.stop
                        v-for="element in classesByPeriod"
                        :key="element.id"
                      >
                        {{ element.subject.code }}-{{ element.code }}
                      </v-chip>
                    </draggable>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="0" md="2" class="text-center">
                <v-btn text color="white" disabled
                  ><v-icon color="grey" small>arrow_back_ios</v-icon
                  ><v-icon color="grey">arrow_back_ios</v-icon></v-btn
                >
                <v-btn text color="white" disabled
                  ><v-icon color="grey" small>arrow_forward_ios</v-icon
                  ><v-icon color="grey">arrow_forward_ios</v-icon></v-btn
                >
                <div class="my-8">
                  Atenção! As solicitações de matrícula referente às turmas removidas serão excluídas definitivamente!
                </div>
                <v-btn text color="white" disabled
                  ><v-icon color="grey" small>arrow_back_ios</v-icon
                  ><v-icon color="grey">arrow_back_ios</v-icon></v-btn
                >
                <v-btn text color="white" disabled
                  ><v-icon color="grey" small>arrow_forward_ios</v-icon
                  ><v-icon color="grey">arrow_forward_ios</v-icon></v-btn
                >
              </v-col>
              <v-col cols="12" sm="12" md="5">
                <v-card
                  outlined
                  class="mt-2"
                  max-height="400px"
                  min-height="400px"
                >
                  <v-card-title>
                    <div>Turmas disponibilizadas</div>
                    <p class="subtitle-1">para solicitações de matrículas de alunos especiais</p>
                  </v-card-title>
                  <v-card-text class="scroll">
                    <draggable
                      v-model="selectedItems"
                      group="id"
                      @start="drag = true"
                      @end="drag = false"
                    >
                      <v-chip
                        class="my-1 mx-1"
                        color="blue lighten-2"
                        v-for="element in selectedItems"
                        :key="element.id"
                        close
                        @click:close="removeSelected(element)"
                        >{{ element.subject.code }}-{{ element.code }}
                      </v-chip>
                    </draggable>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>
        <v-card-actions class="mb-1">
          <v-row justify="center">
            <v-col cols="6" class="text-center">
              <v-btn outlined color="red" :disabled="disabled" @click="cancel()"
                >Cancelar<v-icon class="ml-2">cancel</v-icon></v-btn
              >
            </v-col>
            <v-col cols="6" class="text-center">
              <v-btn
                @click="putAsyncEnrollmentPeriod()"
                outlined
                color="green"
                :disabled="disabled"
                >Atualizar<v-icon class="ml-2">check</v-icon></v-btn
              >
            </v-col>
            <v-col cols="12" class="text-center">
              <label class="">{{ errorMessage }}</label>
            </v-col>
            <v-col cols="12" class="text-center mb-2 mt-1">
              <v-progress-linear
                indeterminate
                color="green"
                v-show="progress"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template> 

<script>
import { mapState, mapActions } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "EnrollmentPeriodInsert",
  props: ["item"],
  components: { draggable },
  data() {
    return {
      errorMessage: "",
      progress: false,
      dialog: false,
      disabled: false,
      classesByPeriod: [],
      selectedItems: [],
      beginDate: new Date(this.item.begin).toISOString().substr(0, 10),
      endDate: new Date(this.item.end).toISOString().substr(0, 10),
      menu: false,
      menu2: false,
      modal: false,
      endMenu: null,
      beginMenu: null,
      year: new Date(this.item.begin).getFullYear(),
      semester: new Date(this.item.begin).getMonth() < 7 ? 1 : 2,
      semesters: [0, 1, 2, 3, 4, 5, 6],
      years: [new Date().getFullYear(), new Date().getFullYear() + 1],
      beginDateRules: [
        (dt) =>
          dt <= this.endDate ||
          !dt > this.endDate ||
          "A data de início não pode ser posterior a data de término!",
        (dt) =>
          dt < new Date() ||
          !dt > new Date() ||
          "A data de início não pode ser anterior a data atual!",
      ],
      endDateRules: [
        (dt) =>
          dt >= this.beginDate ||
          !dt < this.beginDate ||
          "A data de término não pode ser anterior a data de início!",
      ],
    };
  },

  created() {
    this.selectedItems = JSON.parse(JSON.stringify(this.item.classes));
    this.getAsyncClassesByLevelAndYearAndSemester();
    this.classesByPeriod = this.diff(this.classes, this.selectedItems);
  },

  computed: {
    ...mapState("classes", ["classes"]),

    getClassesByPeriod: {
      get() {
        return this.classesByPeriod;
      },
      set(value) {
        console.log(value);
      },
    },
  },

  methods: {
    ...mapActions("enrollmentPeriod", ["ActionPutEnrollmentPeriod"]),
    ...mapActions("classes", ["ActionGetClassesByLevelAndYearAndSemester"]),

    async getAsyncClassesByLevelAndYearAndSemester() {
      if (this.year !== null && this.semester !== null) {
        try {
          this.disabled = true;
          this.progress = true;
          let path = {
            level: "p",
            year: this.year,
            semester: this.semester,
          };
          await this.ActionGetClassesByLevelAndYearAndSemester(path);
        } catch (e) {
          this.errorMessage = e.bodyText;
        } finally {
          this.disabled = false;
          this.progress = false;
        }
      }
    },

    async putAsyncEnrollmentPeriod() {
      try {
        this.disabled = true;
        this.progress = true;

        let payload = {
          enrollmentPeriodId: this.item.id,
          begin: this.beginDate,
          end: this.endDate,
          classesId: this.catchClassesID(),
        };
        //console.log(payload);
        await this.ActionPutEnrollmentPeriod(payload);
        this.cancel();
      } catch (e) {
        this.errorMessage = e.bodyText;
      } finally {
        this.disabled = false;
        this.progress = false;
      }
    },

    getClassProfessor(item) {
      let value = "";
      if (item)
        for (let professor of item.professors) value += professor.name + " ";
      else value = "";
      return value;
    },

    getClassSchedule(item) {
      let value = "";
      if (!isNaN(item))
        for (let schedule of item.classSchedules) value += schedule + " ";
      else value = "";
      return value;
    },

    removeSelected(item) {
      const index = this.selectedItems.findIndex((v) => v.id === item.id);
      if (index >= 0) {
        this.selectedItems.splice(index, 1);
        this.classesByPeriod.push(item);
      }
    },

    selectItem(element) {
      const index = this.classesByPeriod.findIndex((v) => v.id === element.id);
      if (index >= 0) {
        this.classesByPeriod.splice(index, 1);
        this.selectedItems.push(element);
      }
    },

    cancel() {
      this.dialog = false;
      this.errorMessage = "";
    },

    catchClassesID() {
      let ids = [];
      this.selectedItems.map((e) => {
        ids.push(e.id);
      });
      return ids;
    },

    diff(a, b) {
      return a.filter(({ id: id1 }) => !b.some(({ id: id2 }) => id1 === id2));
    },
  },

  watch: {
    item(newValue) {
      this.beginDate = newValue.begin;
      this.endDate = newValue.end;
    },

    classes(newValue) {
      this.classesByPeriod = [];
      this.classesByPeriod = this.diff(newValue, this.selectedItems);
    },

    /*  beginDate(newValue){
      if(newValue >= this.endDate)
        console.error('Data de início maior que a data de término')
    },

    endDate(newValue){
     if(newValue <= this.beginDate)
        console.error('Data de término menor que a data de início')
    } */
  },
};
</script>

<style>
</style>