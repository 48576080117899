var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", md3: "" } },
        [
          _c(
            "v-row",
            { attrs: { wrap: "", justify: "start", align: "start" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Filtrar período",
                      "single-line": "",
                      "append-icon": "search"
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-flex",
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              search: _vm.search,
              items: _vm.items,
              "item-key": "id",
              loading: _vm.loading,
              "hide-default-footer": "",
              "loading-text": "Buscando períodos de matrícula",
              "no-data-text": "Não foram encontradas períodos de matrícula"
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(props) {
                  return [
                    _c("tr", [
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(_vm.dmy(props.item.begin)))
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(_vm.dmy(props.item.end)))
                      ]),
                      _c(
                        "td",
                        { staticClass: "text-xs-left" },
                        _vm._l(props.item.classes, function(c) {
                          return _c(
                            "v-tooltip",
                            {
                              key: c.id,
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-chip",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mr-1 mb-2",
                                                attrs: {
                                                  small: "",
                                                  outlined: "",
                                                  color: "black"
                                                }
                                              },
                                              "v-chip",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v(_vm._s(c.subject.code))]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    c.subject.code + " - " + c.subject.name
                                  )
                                )
                              ])
                            ]
                          )
                        }),
                        1
                      ),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.username))
                      ]),
                      _c(
                        "td",
                        { staticClass: "text-xs-left" },
                        [
                          _c(
                            "v-chip",
                            {
                              attrs: {
                                small: "",
                                outlined: "",
                                color: props.item.removed.color
                              }
                            },
                            [_vm._v(_vm._s(props.item.removed.status))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticClass: "text-xs-left" },
                        [
                          _c("EnrollmentPeriodUpdate", {
                            attrs: {
                              item: props.item,
                              disabled:
                                props.item.removed.status === "Encerrado"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticClass: "text-xs-left" },
                        [
                          _c("EnrollmentPeriodDelete", {
                            attrs: {
                              item: props.item,
                              disabled:
                                props.item.removed.status === "Encerrado"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }